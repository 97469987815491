import axios from "~/plugins/axios";
export default {
  provinceList(countryId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/province-list", {
        params: {
          country_id: countryId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  searchCitiesFromCap(cap) {
    // fidelityapi API cannot be invoked in development environment (i.e. localhost),
    // so we use complete URL
    let url = `https://www.ali.it/fidelityapi/api/city/search/${cap}`;
    return axios
      .get(url)
      .then(data => {
        return data.data;
      })
      .catch(error => error);
  },
  getCountries(continentId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/country-list", {
        params: {
          continent_id: continentId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  selectAddressClass(addressId, addressClassId) {
    return axios
      .get("/ebsn/api/tigros-address-class-controller/set-address-class-id", {
        params: {
          address_id: addressId,
          address_class_id: addressClassId
        }
      })
      .then(data => {
        return data.data;
      });
  },
  getInvoiceList(dateFrom, dateTo) {
    return axios
      .get(`/ebsn/api/dokuknox/list?begin=${dateFrom}&end=${dateTo}`)
      .then(data => {
        return data.data.data;
      });
  },

  getEnableFilter() {
    return axios
      .get(`/ebsn/api/sidal-payment-due-date/enable-filter`)
      .then(data => {
        return data.data.data;
      });
  },

  getPaymentDueDateList(filterPIva = true, filterCodeInt = true) {
    var params = {
      filterPIva: filterPIva,
      filterCodeInt: filterCodeInt
    };

    return axios
      .get(`/ebsn/api/sidal-payment-due-date/list?show_all=true`, { params })
      .then(data => {
        return data.data.data;
      });
  },
  getTransactions() {
    return axios.get(`/ebsn/api/payment-due-date/transactions`).then(data => {
      return data.data.data;
    });
  },
  updatePreferContact(contactPreferPersonInfos) {
    let url = "/ebsn/api/sidal-registration/update_contact_preference";
    return axios
      .post(url, contactPreferPersonInfos)
      .then(response => response.data.data.user)
      .catch(err => err);
  },
  updateUserDetail(user) {
    let url = "/ebsn/api/sidal-registration/update_user_detail";
    return axios
      .post(url, user)
      .then(response => response.data.data.user)
      .catch(err => err);
  },
  checkCustomerRegister(
    email,
    password,
    vatCode,
    fidelity,
    flag1,
    flag2,
    flag3,
    fiscalCode,
    defaultPdv,
    email2
  ) {
    var params = {
      vat_code: vatCode,
      cod_int: fidelity,
      e_mail: email,
      password: password,
      person_info_type_id_1: flag1,
      person_info_type_id_2: flag2,
      person_info_type_id_3: flag3,
      fiscal_code: fiscalCode,
      person_info_type_id_4: defaultPdv,
      e_mail2: email2
    };
    let url = "/ebsn/api/sidal-registration/check_customer_code";
    return axios
      .get(url, { params })
      .then(response => response.data)
      .catch(err => err);
  },
  registerUser(user) {
    let url = "/ebsn/api/sidal-registration/register_user";
    return axios
      .post(url, user)
      .then(response => response.data)
      .catch(err => err);
  },
  changeDateOfOrder(orderid, neworderdate) {
    // let url = `/ebsn/api/order/change-date?order_id=${orderid}&new_order_date=${neworderdate}`;
    let url = `/ebsn/api/order/change-date`;
    var params = {
      order_id: orderid,
      new_order_date: neworderdate
    };
    return axios
      .post(url, params)
      .then(response => response.data.data)
      .catch(err => err);
  }
};
